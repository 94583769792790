<template>
    <div id="pageTable">
        <h3 class="tituloPrincipal pt-3">
            <v-icon class="fa-rotate-270 iconTitulo">maximize</v-icon>
            Concepto de Nómina
        </h3>
        <v-container xl fluid>
            <v-row>
                <v-card color="white" width="100%">
                    <v-col cols="12" lg="12">
                        <data-table
                            :columns="columns"
                            :items="tableData.data"
                            :paginationInfo="tableData.paginatorInfo"
                            @pagination="getPaginationInfo"
                            :showAdvancedFilters="false"
                            :perPage="[10,25,50,100]"
                        >
                            <tbody slot="body" slot-scope="{ data }">
                                <tr :key="item.id" v-for="item in data">
                                    <td class="tipoLetra700Azul pl-4">{{item.clave}}</td>
                                    <td class="tipoLetra700Azul pl-4">{{item.descripcion}}</td>
                                    <td>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </data-table>
                    </v-col>
                </v-card>
            </v-row>

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn color="info" v-on="on" fixed dark fab bottom right
                        @click="abrirModal('add')" style="transform-origin: center center 0px;background: radial-gradient(#12cbff, #05c4fe, #01C7FF 70%) !important;">
                        <v-icon dark>add</v-icon>
                    </v-btn>
                </template>
                <span>Agregar</span>
            </v-tooltip>

            <template>
                <v-dialog v-model="dialog" persistent max-width="600px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center titulo">
                                <span class="justify-center titulo" v-text="tituloModal"></span>
                            </v-card-title>

                            <v-tabs
                                v-model="tab"
                                background-color="transparent"
                                color="#101C5A"
                                grow
                                class="mb-5"
                            >
                                <v-tab  href="#Principal">
                                    Principal
                                </v-tab>
                                <v-tab  href="#Importe">
                                    Importe Total
                                </v-tab>
                                <v-tab  href="#Importe1">
                                    Importe 1
                                </v-tab>
                                <v-tab  href="#Importe2">
                                    Importe 2
                                </v-tab>
                                <v-tab  href="#Importe3">
                                    Importe 3
                                </v-tab>
                                <v-tab  href="#Importe4">
                                    Importe 4
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item :key="1" value="Principal">
                                    <v-card flat>
                                        <v-card-text>
                                            <div id="padre" v-show="isLoading">
                                                <div id="loading">
                                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                                </div>
                                            </div>
                                            
                                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                                <v-row>
                                                    <v-col cols="12" xs="12" sm="6" md="6">
                                                        <ValidationProvider v-slot="{ errors }" name="Clave" rules="required">
                                                            <v-text-field label="Clave" class="pa-0 ma-0" v-model="conceptoNom.clave" :error-messages="errors" required>
                                                                <template class="pa-0 ma-0" slot="prepend-inner">
                                                                <v-icon class="tamIcono invertirColor">description</v-icon>
                                                                <v-icon class="fa-rotate-270 pt-3 iconoDelgado">maximize</v-icon>
                                                                </template>
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6">
                                                        <ValidationProvider v-slot="{ errors }" name="Descripcion" rules="required">
                                                            <v-text-field label="Descripción" class="pa-0 ma-0" v-model="conceptoNom.descripcion" :error-messages="errors" required>
                                                                <template class="pa-0 ma-0" slot="prepend-inner">
                                                                <v-icon class="tamIcono invertirColor">description</v-icon>
                                                                <v-icon class="fa-rotate-270 pt-3 iconoDelgado">maximize</v-icon>
                                                                </template>
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6">
                                                        <ValidationProvider v-slot="{ errors }" name="Tipo" rules="required">
                                                            <v-autocomplete
                                                                label="Tipo"
                                                                :items="tipos"
                                                                item-text="descripcion"
                                                                item-value="id"
                                                                persistent-hint
                                                                v-model="tipo_value"
                                                                class="pa-0 ma-0"
                                                                required 
                                                                :error-messages="errors" >
                                                                <template slot="prepend-inner">
                                                                    <v-icon class="tamIcono iconoDelgado">person_outline</v-icon>
                                                                    <v-icon class="fa-rotate-270 pt-3 iconoDelgado">maximize</v-icon>
                                                                </template>
                                                            </v-autocomplete>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6">
                                                        <ValidationProvider v-slot="{ errors }" name="Clave agrupada a SAT" rules="required">
                                                            <v-autocomplete
                                                                label="Clave agrupada a SAT"
                                                                :items="claves"
                                                                item-text="descripcion"
                                                                item-value="id"
                                                                persistent-hint
                                                                v-model="claveSAT"
                                                                class="pa-0 ma-0"
                                                                required 
                                                                :error-messages="errors" >
                                                                <template slot="prepend-inner">
                                                                    <v-icon class="tamIcono iconoDelgado">person_outline</v-icon>
                                                                    <v-icon class="fa-rotate-270 pt-3 iconoDelgado">maximize</v-icon>
                                                                </template>
                                                            </v-autocomplete>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6">
                                                        <v-container fluid>
                                                            <p>Volumen</p>
                                                            <v-radio-group v-model="conceptoNom.volumen" :mandatory="false">
                                                                <v-radio label="Individual" value="Individual"></v-radio>
                                                                <v-radio label="General" value="General"></v-radio>
                                                            </v-radio-group>
                                                        </v-container>
                                                    </v-col>
                                                    <v-col cols="12" xs="12" sm="6" md="6">
                                                        <v-container fluid>
                                                            <p>Estado</p>
                                                            <v-checkbox class="pa-0 ma-0" v-model="conceptoNom.estado_activo" label="Activo"></v-checkbox>
                                                            <v-checkbox class="pa-0 ma-0" v-model="conceptoNom.imprimir" label="Imprimir"></v-checkbox>
                                                            <v-checkbox class="pa-0 ma-0" v-model="conceptoNom.multiple" label="Multiple"></v-checkbox>
                                                        </v-container>
                                                    </v-col>
                                                    
                                                    
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item :key="2" value="Importe">
                                    <v-card flat>
                                        <v-card-text>Importe total</v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item :key="2" value="Importe1">
                                    <v-card flat>
                                        <v-card-text>Importe 1 calculos para el importe 1</v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item :key="2" value="Importe2">
                                    <v-card flat>
                                        <v-card-text>Importe 2</v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item :key="2" value="Importe3">
                                    <v-card flat>
                                        <v-card-text>Importe 3  Otros calculos</v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item :key="2" value="Importe4">
                                    <v-card flat>
                                        <v-card-text>Importe 4 Calculos para otros tipos de importes</v-card-text>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                            <v-card-actions class="justify-center">
                                <v-btn class="btnGuardar" @click="guardar()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                            </v-card-actions>
                            <v-card-actions class="justify-center">
                                <v-btn class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/ConceptoNomina';
import Notify from '@/plugins/notify';
import apiPercepcion from '@/api/nominas/tipoPercepcion';
//import apiConceptoNomina from '@/api/nominas/conceptoNomina';

export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Clave',
                    name: 'clave',
                    filterable: true
                },
                {
                    label: 'Descripcion',
                    name: 'descripcion',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    filterable: false
                }
            ],
            filters         : {},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            conceptoNom      : {
                id              : null,
                clave           : null,
                tipo            : null,
                descripcion     : null,
                volumen         : 'Individual',
                estado_activo   : false,
                imprimir        : false,
                multiple        : false,
            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isLoading       : false,
            isSaving        : false,
            tipos   : [
                    {
                        id:1,
                        descripcion: 'Concepto'
                    },
                    {
                        id:2,
                        descripcion: 'Percepción'
                    }
                ],
            tipo_value      : null,
            claveSAT        : null,
            claves          : [],
            tab             : "Principal"
        }
    },
    watch: {
        tipo_value: function(val){
            this.getClaveSat(val);
        }        
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }

            this.$apollo.queries.conceptoNomina;
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoading  = true;

            if(accion == "add"){
                this.tituloModal = "Registrar Concepto de Nómina";
                
            } else {
                this.tituloModal = "Actualizar Concepto de Nómina";
                /* apiConceptoNomina.get(data.id).then((response)=>{
                    this.conceptoNom.id              = response.id;
                    this.conceptoNom.clave           = response.clave;
                    this.conceptoNom.descripcion     = response.descripcion;
                    this.conceptoNom.tipo            = response.tipo;
                })
                .catch(error => {
                    this.resetValues();
                    Notify.ErrorToast(error.response.data.error);
                }); */

            }

            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.conceptoNom = {
                id              : null,
                clave           : null,
                tipo            : null,
                descripcion     : null,
            };  
            //tipo_value      = null;
            //claveSAT        = null;
            //claves          = [];         
        },
        eliminar(data){
            console.log("eliminar concepto de Nomina");
            /* Notify.Alert(
                "¡Hey! ¿Estás seguro?",
                "¿Estás seguro que deseas eliminar el Concepto de Nómina " + data.descripcion + "?",
                "SI! QUIERO ELIMINARLO",
                () => {
                    apiConceptoNomina.delete(data.id).then(response => {
                        Notify.Success("Concepto de Nómina eliminado", "El Concepto de Nómina ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.conceptoNomina.refetch();
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    });
                }
            ) */
        },
        
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                if(this.accion === "add") {
                    console.log("agregar")
                    Notify.Success("Excelente!", "El Concepto de Nomina se guardó satisfactoriamente.");
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                    });
                   /*  apiConceptoNomina.store(this.conceptoNom)
                    .then((response) => {
                        Notify.Success("Excelente!", "El Concepto de Nomina se guardó satisfactoriamente.");
                        this.$apollo.queries.conceptoNomina.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    }); */
                }
                else if(this.accion === "update") {
                    console.log("actualizar");
                    /* apiConceptoNomina.update(this.conceptoNom)
                    .then((response) => {
                        Notify.Success("Excelente!", "El Concepto de Nomina se actualizó satisfactoriamente.");
                        this.$apollo.queries.conceptoNomina.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    }); */
                }
            });
        },
        getClaveSat(tipo){
            switch (tipo){
                case 1:
                    this.claves = [];
                    break;
                case 2:
                    let parametros = { activo: true, paginate: false};
                    apiPercepcion.find(parametros).then((response)=>{
                        this.claves = response.data;
                    })
                    .catch(err => {
                        this.resetValues();
                        
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;
                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    });
                    break;
                default:
                    break;
            }
        }
    },
    apollo: {
        conceptoNomina: {
            query       : queries.conceptoNominaTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : 'clave'
                }
            },
            result({loading, error}) {
                this.loading    = false;
                this.tableData  = this.conceptoNomina;   
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -6%;
    }
    .titulo {
        font-family: 'Montserrat' !important;
        font-size: 28px !important;
        font-weight: 700 !important;
        color: #0f3043 !important;
        letter-spacing: -0.68px;
        line-height: 34px;
    }
    .btnGuardar {
        font-family: 'Montserrat';
        font-size: 13px !important;
        font-weight: 700 !important;
        background-color: #183799 !important;
        border-radius: 23px 23px 23px 23px;
        color: white !important;
        width: 70% !important;
        height: 40px !important;
    }
    .btnCerrar {
        font-family: 'Montserrat';
        font-size: 13px !important;
        font-weight: 700 !important;
        background-color: white !important;
        border-radius: 23px 23px 23px 23px;
        color: rgba(121, 141, 152, 0.6);
        width: 70% !important;
        height: 40px !important;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .tipoLetra700Azul {
        font-weight: 700;
        letter-spacing: -0.34px;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
</style>

